/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// GovBr
@import '../node_modules/@govbr-ds/core/src/core.scss';

// HTML básico
BODY {
  font-family: Rawline, Raleway, sans-serif;
  // font-size: 16px;
  // font-weight: normal;
}

// componentes Ionic
ion-input {
  font-family: Rawline, Raleway, sans-serif;
  --placeholder-font-style: italic !important;
  --padding-start: 14px !important;
}

ion-content {
  font-family: Rawline, Raleway, sans-serif;
}
ion-grid {
  font-family: Rawline, Raleway, sans-serif;
}

ion-checkbox {
  --background: var(--background-light);
  --background-checked: var(--background-light);
  --border-color: silver;
  --border-color-checked: silver;
  --border-radius: 4px;
  --border-style: solid;
  --border-width: 1px;
  --checkmark-color: dodgerblue;
  --size: 24px;
}

ion-modal {
  --height: 50%;
  --border-radius: 5px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  ion-header {
    .br-button {
      color: white;
    }
  }
}
// ion-modal::part(backdrop) {
//   background: rgba(209, 213, 219);
//   opacity: 1;
// }
ion-modal ion-toolbar {
  // --background: rgb(14 116 144);
  --background: var(--blue-vivid-60);
  --color: white;
}

.ion-modal-cookies {
  --height: 78%;
  --width: 400px;
  --border-radius: 10px;
  --box-shadow: 0; // 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  // color: var(--text-color);
  font-family: Rawline, Raleway, sans-serif;
  font-size: 12px;

  ion-header {
    color: var(--text-color);
    padding: 14px;
    padding-top: 30px;
  }

  ion-footer {
    padding: 14px;
  }

  .div-cookie-content {
    // border: solid 1px red;
    color: var(--text-color);
    padding: 10px;
  }

  .div-cookie-botao-fechar {
    position: absolute;
    top: 2px;
    right: 2px;
    // border: solid 1px blue;

    .br-button {
      font-size: 24px !important;
      color: var(--text-color);
    }
  }
  .div-cookie-titulo {
    font-size: 13px;
    font-weight: bold;
  }
  .div-cookie-link {
    font-size: 13px;
    font-weight: bold;
    padding-top: 3px;
    padding-bottom: 8px;
  }
  .div-cookie-switch-container {
    // border: solid 1px green;
    //   display: flex;
    min-height: 30px;
  }
  .div-cookie-titulo-secao {
    // border: solid 1px orange;
    font-weight: bold;
    float: left;
    // min-height: 24px;
    padding-top: 2px;
  }
  .div-cookie-switch {
    // border: solid 1px red;

    float: right !important;

    label {
      color: var(--color-primary-lighten-01); // !important;
      font-family: Rawline, Raleway, sans-serif;
      font-size: 12px;
      font-weight: normal;
    }
  }
  .label-cookie-desativado {
    color: var(--gray-40) !important;
  }
  // .switch-data{
  //   color: var(--color-primary-lighten-02) !important;
  //   font-size: 10px !important;
  // }
}

.div-inicio-cards {
  // border: solid 1px orange;
  display: flex;
  flex-wrap: wrap;
  ion-card {
    // border: solid 1px tan;
    margin: 0;
    margin-top: 10px;
    margin-right: 10px;
    min-width: 200px;
    width: 200px;
  }
}
ion-card {
  border: solid 1px var(--blue-warm-vivid-80);
  --color: var(--color);
  font-family: Rawline, Raleway, sans-serif;
  ion-card-header {
    i {
      // border: solid 1px red;
      // padding-top: 12px;
      color: white;
      font-size: 24px !important;
      position: relative;
      left: 0px;
      top: 11px;
    }
    ion-card-title {
      --color: var(--blue-warm-vivid-80);
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.icone-card-wrapper {
  background-color: var(--blue-warm-vivid-80);
  border-radius: 50%;
  height: 46px;
  width: 46px;
  text-align: center;
}

// ion-alert
.custom-alert {
  // --backdrop-opacity	Opacity of the backdrop
  --background: white; //	Background of the alert
  // --height	Height of the alert
  // --max-height	Maximum height of the alert
  // --max-width	Maximum width of the alert
  // --min-height: 120px; //	Minimum height of the alert
  // --min-width	Minimum width of the alert
  // --width	Width of the alert

  .alert-head {
    background-color: var(--blue-vivid-60);
  }
  .alert-title {
    color: white;
    font-family: Rawline, Raleway, sans-serif;
    font-size: 16px;
    // font-weight: normal;
  }
  .alert-button-group {
    justify-content: left;
  }
  .alert-button {
    border: solid 1px var(--interactive);
    border-radius: 20px;
    font-family: Rawline, Raleway, sans-serif;
    font-size: 16px;
    height: 34px;
    min-width: 80px;
    text-transform: none;
    color: var(--interactive);
    // min-width: 90px;
  }
  .alert-button:hover {
    background-color: var(--blue-10);
  }
  .sc-ion-alert-md {
    justify-content: center;
  }
}

// demais classes
.clickable {
  cursor: pointer;
}
.text-centered {
  text-align: center;
}
.text-right {
  text-align: right;
}
.float-right {
  float: right !important;
}
.to-front {
  z-index: 10;
}

.div-page-content {
  height: 100% !important;
  // margin-top: 10px;
  margin-left: 10px;
  // margin-right: 16px;
  // overflow-y: scroll;
}
.div-page-content-mobile {
  margin-right: 16px;
}

.div-titulo-secao {
  // border: solid 1px tomato; --blue-warm-vivid-80
  background-image: linear-gradient(to right, var(--blue-vivid-60), white);
  color: white;
  font-family: Rawline, Raleway, sans-serif;
  font-size: 16px;
  padding: 8px 8px 10px 12px;
}
.div-subtitulo-secao {
  // border: solid 1px tomato; --blue-warm-vivid-80
  // background-image: linear-gradient(to top, var(--blue-vivid-60), white);
  color: var(--color);
  font-family: Rawline, Raleway, sans-serif;
  font-size: 16px;
  padding: 8px 8px 10px 12px;
}

.div-subtitulo-secao-2 {
  // border: solid 1px tomato; --blue-warm-vivid-80
  // background-image: linear-gradient(to top, var(--blue-vivid-60), white);
  color: var(--color);
  font-family: Rawline, Raleway, sans-serif;
  font-size: 16px;
  padding: 8px 8px 10px 1px;
}

// ----- br-button
.br-button {
  font-family: Rawline, Raleway, sans-serif;
}

// ----- br-header
.br-header {
  // box-shadow: var(--surface-shadow-sm) !important;
  --header-shadow: var(--surface-shadow-sm);
}

// ---------- split pane
ion-split-pane {
  // --border, --side-max-width: 100px;, --side-min-width
  padding-left: 3px;
  --side-width: 100px;
}

// ---------- accordion
.accordion-scroll::-webkit-scrollbar {
  background: #eee;
  width: 4px;
}

/* Buttons */
.accordion-scroll::-webkit-scrollbar-button:single-button {
  background-color: silver;
  display: block;
  background-size: 8px;
  background-repeat: no-repeat;
}
::-webkit-scrollbar-thumb {
  background-color: silver;
}
// .accordion-scroll::-webkit-scrollbar-button:single-button:vertical:decrement {
//   height: 12px;
//   width: 16px;
//   background-position: center 2px;
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(73, 73, 73)'><polygon points='50,00 0,50 100,50'/></svg>");
// }
// .accordion-scroll::-webkit-scrollbar-button:single-button:vertical:increment {
//   height: 12px;
//   width: 16px;
//   background-position: center 2px;
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(73, 73, 73)'><polygon points='0,0 100,0 50,50'/></svg>");
// }

ion-accordion {
  ion-item {
    font-family: Rawline, Raleway, sans-serif;
    font-size: 14px;
    --background: var(--gray-2);
    --background-hover: var(--blue-warm-20) !important;
    --background-hover-opacity: 1;
    --color: var(--interactive); //--blue-warm-vivid-80);
    cursor: pointer;

    i {
      color: inherit; //var(--blue-warm-vivid-80);
      // border: solid 1px red;
      padding-left: 6px;
      padding-right: 36px;
    }
  }

  .ion-accordion-toggle-icon {
    color: var(--interactive); //'--blue-warm-vivid-80);
    font-size: 16px;
    font-weight: bold;
  }
}

.accordion-sem-icone {
  .ion-accordion-toggle-icon {
    color: transparent;
  }
}
.accordion-item-ativo {
  --background: var(--blue-warm-vivid-80);
  --color: white;
}

// ---------- card
.card-container {
  display: flex;
}
ion-card {
  cursor: pointer;
  ion-card-header {
    text-align: center;
  }
}

// ---------- select
.br-select {
  min-width: 100%;
}
.br-input {
  width: 100% !important;
}
.br-list-block {
  display: block !important;
  z-index: 10 !important;
}
ion-input {
  input {
    border: solid 1px #5a5a5a !important;
    border-radius: 5px !important;
  }
}

// ---------- br-switch
.br-switch input + label::before {
  background: var(--blue-warm-10);
  border: solid 1px var(--blue-warm-20);
}

// ---------- calendario/datePicker
ion-popover {
  --width: 330px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

// ---------- avatar
.br-avatar .content {
  border-radius: 50%;
  width: var(--avatar-size);
  // border: solid 1px blue;
  border-bottom: solid 4px transparent; //centralização da letra
}
// sign-in/avatar button
.br-sign-in,
.br-button.sign-in {
  height: 52px;
  padding: 6px !important;
}
.avatar-dropdown {
  // border: solid 1px red;
  position: absolute;
  box-shadow: var(--surface-shadow-sm) !important;
  font-family: Rawline, Raleway, sans-serif;
  font-size: 14px;
  --line-height: var(--font-line-height-medium);
  width: 215px;
  z-index: 10;
}

// ----- br-message
.br-message {
  font-family: Rawline, Raleway, sans-serif;
  font-size: 14px;
  width: 98%;
}
.icon-danger {
  i {
    color: var(--danger) !important;
  }
}
.icon-success {
  i {
    color: var(--success) !important;
  }
}
.icon-info {
  i {
    color: var(--info) !important;
  }
}
.icon-warning {
  i {
    color: var(--message-color-icon) !important;
  }
}

// ----- br-feedback (validação do input)
.icon-feedback-claro {
  i {
    color: white !important;
  }
}
.icon-feedback-escuro {
  i {
    color: var(--message-text) !important;
  }
}

// tabela
// var(--background-alternative) //titulo
.linhaImpar {
  background-color: var(--background);
  border-bottom: solid 1px var(--gray-20);
}
// .linhaPar {
//     background-color: #f3f3f3;
//     border-bottom: solid 1px silver;
// }
.linhaImpar:hover,
.linhaPar:hover {
  background-color: var(--gray-10); //#e5eef5;
  cursor: pointer;
}

// --- br-listbox
.label-titulo-listbox {
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-medium);
  margin-bottom: var(--spacing-scale-half);
}
.div-listbox {
  background-color: var(--background);
  border: solid 1px #5a5a5a;
  border-radius: 5px 2px 2px 5px;
  height: 155px;
  overflow: auto;

  .div-item-listbox {
    border-bottom: solid 1px silver;
    color: var(--text-color);
    cursor: pointer;
    font-family: Rawline, Raleway, sans-serif;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    padding-left: 14px;
    padding-top: 12px;
    padding-bottom: 10px;

    ion-checkbox {
      --background: var(--background-light);
      --background-checked: var(--background-light);
      --border-color: silver;
      --border-color-checked: silver;
      --border-radius: 4px;
      --border-style: solid;
      --border-width: 1px;
      --checkmark-color: dodgerblue;
      --size: 24px;
    }
    ion-label {
      color: var(--text-color) !important;
      position: relative;
      top: -6px;
      padding-left: 10px;
    }
  }
}

// outras classes
.display-flex {
  display: flex;
}

.div-lista-checkbox {
  // border: solid 1px tomato;
  // display: flex; // responsividade
  .div-item-checkbox {
    padding: 6px 20px 4px 2px;
    white-space: nowrap;
    ion-label {
      padding-left: 12px;
      position: relative;
      top: -7px;
    }
  }
}

.input-desabilitado {
  background-color: var(--color-secondary-03) !important; //rgb(220,220,220) !important;
  color: var(--text-color) !important;
}

.toast {
  font-family: Rawline, Raleway, sans-serif;
  font-size: 15px;
  --color: var(--text-color);
  --min-width: 96%;
}
.toast-success {
  --background: var(--success-alternative);
  --button-color: var(--success);
}
.toast-success::part(icon),
.toast-success::part(button) {
  color: #690; //var(--sucess);
}
.toast-danger {
  --background: var(--danger-alternative);
  --button-color: var(--danger);
}
.toast-danger::part(icon),
.toast-danger::part(button) {
  color: var(--danger);
}
.toast-warning {
  --background: var(--warning-alternative);
  --button-color: var(--warning);
}
.toast-warning::part(icon),
.toast-warning::part(button) {
  color: orange; //var(--warning);
}

.div-versao-info {
  background-color: red important; //whitesmoke;
  top: 4px;
  color: white; //var(--blue-vivid-60);
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 10px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

.div-versao-info-rodape {
  bottom: 40px;
  color: white; //var(--blue-vivid-60);
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 10px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

.mensagem-informativa {
  font-family: Rawline, Raleway, sans-serif;
  font-size: 11px;
  color: var(--danger);
}

@import './app/tooltip.scss';

.br-header {
  --header-background: var(--color-secondary-01);
  --header-shadow: var(--surface-shadow-sm);
  --header-padding: var(--header-padding-medium);
  --header-padding-small: var(--spacing-scale-base);
  --header-padding-medium: var(--spacing-scale-2x);
  --header-padding-large: var(--spacing-scale-3x);
  --header-logo-size: var(--header-logo-medium);
  --header-logo-small: 16px;
  --header-logo-medium: 24px;
  --header-logo-large: 40px;
  --header-sign-color: var(--color-secondary-07);
  --header-title-size: var(--font-size-scale-base);
  --header-title-color: var(--color-secondary-09);
  --header-subtitle-size: var(--font-size-scale-down-01);
  --header-subtitle-color: var(--color-secondary-07);
  --header-logo-desktop-width: 120px;
  --header-logo-desktop-height: 48px;
  --header-logo-compact: 60px;
  --header-logo-compact-height: 32px;
  --header-logo-mobile-width: 80px;
  --header-logo-mobile-height: 40px;
  --header-sticky-z-index: var(--z-index-layer-2);
  background: var(--header-background);
  box-shadow: var(--header-shadow);
  display: flex;
  padding: var(--header-padding) 0;
  position: relative;
}
.br-header .header-top,
.br-header .header-bottom {
  display: flex;
}
.br-header .header-top {
  justify-content: flex-end;
}
.br-header .header-bottom {
  justify-content: space-between;
  margin-top: var(--header-padding-small);
}
@media (min-width: 576px) {
  .br-header {
    --header-padding: var(--header-padding-medium);
  }
}
.br-header.small {
  --header-padding: var(--header-padding-small);
}
.br-header.medium {
  --header-padding: var(--header-padding-medium);
}
.br-header.large {
  --header-padding: var(--header-padding-large);
}
.br-header .header-logo {
  align-items: center;
  display: flex;
  flex: 1;
  float: left;
}
.br-header .header-logo img {
  margin-right: var(--spacing-scale-base);
  max-height: var(--header-logo-size);
}
.br-header .header-logo .br-divider {
  display: none;
}
.br-header .header-logo .header-sign {
  color: var(--header-sign-color);
  display: none;
  font-weight: 500;
  overflow: hidden;
}
.br-header[data-no-logo] .header-logo img {
  display: none;
}
.br-header[data-no-sign] .header-logo .header-sign {
  display: none;
}
@media (min-width: 576px) {
  .br-header .header-logo {
    --header-logo-size: var(--header-logo-large);
    margin: 0;
  }
  .br-header .header-logo .br-divider {
    display: block;
  }
  .br-header .header-logo .header-sign {
    display: block;
  }
}
.br-header .header-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.br-header .header-actions .dropdown.show .br-list {
  display: block;
  z-index: var(--z-index-layer-1);
}
.br-header .header-actions .dropdown.show .br-button.active .svg-inline--fa,
.br-header .header-actions .dropdown.show .br-button.active .fa,
.br-header .header-actions .dropdown.show .br-button.active .fab,
.br-header .header-actions .dropdown.show .br-button.active .fad,
.br-header .header-actions .dropdown.show .br-button.active .fal,
.br-header .header-actions .dropdown.show .br-button.active .far,
.br-header .header-actions .dropdown.show .br-button.active .fas {
  transform: rotate(180deg);
}
.br-header .header-actions .dropdown.show .br-notification {
  display: block;
  right: -16px;
  width: 100vw;
  z-index: var(--z-index-layer-1);
}
.br-header .header-actions .dropdown.show .br-notification div.close {
  display: none;
}
.br-header .header-actions .dropdown:not(.show) .br-notification {
  display: none;
}
@media (max-width: 1279px) {
  .br-header .header-actions .dropdown:not(.show) .br-list {
    display: none;
  }
}
.br-header .header-actions .header-links .br-list,
.br-header .header-actions .header-functions .br-list {
  background: var(--header-background);
  left: -4em;
}
.br-header .header-actions .header-links .br-item,
.br-header .header-actions .header-functions .br-item {
  white-space: nowrap;
}
.br-header .header-actions .header-links ul,
.br-header .header-actions .header-functions ul {
  display: none;
}
.br-header .header-actions .header-functions .br-list .br-button {
  --button-size: auto;
  --button-font-size: var(--font-size-scale-base);
  --button-font-weight: var(--item-font-weight);
  border-radius: 0;
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--item-font-weight);
  justify-content: flex-start;
  padding: var(--item-padding);
  width: 100%;
}
.br-header .header-actions .header-functions .br-list .br-button .text {
  margin-left: var(--spacing-scale-2x);
}
.br-header .header-actions .header-functions .br-item {
  padding: 0;
}
.br-header .header-actions .header-functions ul {
  display: none;
}
.br-header .header-actions .header-login {
  margin-left: var(--spacing-scale-base);
}
.br-header .header-actions .sign-in {
  --button-padding: 0 var(--spacing-scale-2x);
}
.br-header .header-actions .avatar {
  align-items: center;
  display: flex;
}
.br-header .header-actions .avatar .br-list {
  min-width: 200px;
  right: 0;
  top: 100%;
}
.br-header[data-no-links] .header-actions .header-links {
  display: none;
}
.br-header[data-no-functions] .header-actions .header-functions {
  display: none;
}
.br-header[data-no-login] .header-actions .header-login {
  display: none;
}
@media (min-width: 576px) {
  .br-header .header-actions .header-links .br-list,
  .br-header .header-actions .header-functions .br-list {
    background: var(--header-background);
    left: auto;
    right: 0;
  }
  .br-header .header-actions .header-links .br-notification,
  .br-header .header-actions .header-functions .br-notification {
    width: 100vw;
  }
}
@media (min-width: 1280px) {
  .br-header .header-actions .header-links [data-toggle='dropdown'],
  .br-header .header-actions .header-functions [data-toggle='dropdown'] {
    display: none;
  }
  .br-header .header-actions .header-links .br-list,
  .br-header .header-actions .header-functions .br-list {
    box-shadow: none;
    display: flex;
    margin-right: var(--spacing-scale-2x);
    position: static;
  }
  .br-header .header-actions .header-links .br-list .header,
  .br-header .header-actions .header-functions .br-list .header {
    display: none;
  }
  .br-header .header-actions .header-links .br-item,
  .br-header .header-actions .header-functions .br-item {
    --focus-offset: var(--spacing-scale-half);
    --interactive-rgb: var(--interactive-light-rgb);
    color: var(--interactive-light);
    padding: 0;
  }
  .br-header .header-actions .header-links .br-item:not(:last-child),
  .br-header .header-actions .header-functions .br-item:not(:last-child) {
    border-bottom: 0;
    margin-right: var(--spacing-scale-3x);
  }
  .br-header .header-actions .header-links .br-notification,
  .br-header .header-actions .header-functions .br-notification {
    width: 50vw;
  }
  .br-header .header-actions .header-functions .br-list {
    margin-right: 0;
  }
  .br-header .header-actions .header-functions .br-list .br-button {
    --button-size: var(--button-small);
    border-radius: 50%;
    color: var(--interactive);
    justify-content: center;
    padding: 0;
    width: var(--button-size);
  }
  .br-header .header-actions .header-functions .br-list .br-button .text {
    display: none;
  }
  .br-header .header-actions .header-functions .br-item {
    margin-right: var(--spacing-scale-base);
  }
  .br-header .header-actions .header-functions .br-item:not(:last-child) {
    margin-right: var(--spacing-scale-base);
  }
  .br-header .header-actions .header-search-trigger {
    display: none;
  }
}
.br-header .header-menu {
  align-items: center;
  display: flex;
  flex: 1;
}
.br-header .header-menu .header-menu-trigger + .header-info {
  margin-left: var(--spacing-scale-2x);
}
.br-header .header-menu .header-title {
  color: var(--header-title-color);
  font-size: var(--header-title-size);
}
.br-header .header-menu .header-subtitle {
  color: var(--header-subtitle-color);
  display: none;
  font-size: var(--header-subtitle-size);
  font-weight: 500;
  margin-top: var(--spacing-scale-half);
}
.br-header[data-no-subtitle] .header-menu .header-subtitle {
  display: none;
}
@media (min-width: 576px) {
  .br-header .header-menu {
    --header-title-size: var(--font-size-scale-up-02);
    align-items: flex-start;
  }
  .br-header .header-menu .header-menu-trigger {
    margin-top: 3px;
  }
  .br-header .header-menu .header-info {
    padding-top: var(--spacing-scale-half);
  }
  .br-header .header-menu .header-subtitle {
    display: block;
  }
}
@media (min-width: 1280px) {
  .br-header .header-menu {
    --header-title-size: var(--font-size-scale-up-03);
    --header-subtitle-size: var(--font-size-scale-base);
  }
  .br-header .header-menu .header-info {
    padding-top: 0;
  }
}
.br-header .header-search,
.br-header.compact .header-search {
  align-items: center;
  background: var(--header-background);
  bottom: 0;
  display: none;
  left: 0;
  padding: 0 var(--spacing-scale-2x);
  position: absolute;
  right: 0;
  top: 0;
}
.br-header .header-search .br-input,
.br-header.compact .header-search .br-input {
  --input-size: var(--input-highlight);
  align-items: center;
  display: flex;
  flex: 1;
}
.br-header .header-search .br-input label,
.br-header.compact .header-search .br-input label {
  display: none;
}
.br-header .header-search .br-input input,
.br-header.compact .header-search .br-input input {
  border-color: transparent;
}
.br-header .header-search .br-input input:not(:focus),
.br-header.compact .header-search .br-input input:not(:focus) {
  background: var(--color-secondary-02);
}
.br-header .header-search .br-input .icon,
.br-header .header-search .br-input .br-button,
.br-header.compact .header-search .br-input .icon,
.br-header.compact .header-search .br-input .br-button {
  --focus-offset: 0;
  bottom: auto;
  right: 4px;
  top: auto;
}
.br-header .header-search .search-close,
.br-header.compact .header-search .search-close {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  display: none;
}
.br-header .header-search.active,
.br-header.compact .header-search.active {
  display: flex;
}
.br-header .header-search.active .search-close,
.br-header.compact .header-search.active .search-close {
  display: inline-flex;
}
.br-header[data-no-search] .header-search {
  display: none;
}
@media (min-width: 1280px) {
  .br-header .header-search {
    display: flex;
    padding: 0;
    position: static;
  }
  .br-header .header-search.active .search-close {
    display: none;
  }
  .br-header .br-input {
    min-width: 385px;
  }
}
.br-header .br-input.has-icon .br-button.circle {
  margin-top: 0;
  position: absolute;
  right: 4px;
}
.br-header.compact {
  --header-logo-size: var(--header-logo-small);
  --header-padding: var(--header-padding-small);
}
.br-header.compact .header-actions .header-search-trigger {
  display: block;
}
@media (min-width: 576px) {
  .br-header.compact .container-lg {
    position: relative;
  }
  .br-header.compact .header-top {
    display: block;
  }
  .br-header.compact .header-logo {
    --header-logo-size: var(--header-logo-small);
    float: left;
  }
  .br-header.compact .header-logo .br-divider {
    display: none;
  }
  .br-header.compact .header-sign {
    display: none;
  }
  .br-header.compact .header-actions {
    float: right;
    margin-top: var(--spacing-scale-2x);
    position: relative;
  }
  .br-header.compact .header-bottom {
    clear: left;
  }
  .br-header.compact .header-menu {
    --header-title-size: var(--font-size-scale-up-01);
  }
  .br-header.compact .header-menu .header-menu-trigger {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .br-header.compact .header-menu {
    --header-title-size: var(--font-size-scale-up-02);
  }
}
.br-header[data-sticky] {
  position: sticky;
  top: 0;
  transition: all 150ms ease;
  z-index: var(--header-sticky-z-index);
}
.br-header[data-sticky] .header-logo img,
.br-header[data-sticky] .header-actions,
.br-header[data-sticky] .header-menu-trigger,
.br-header[data-sticky] .header-title {
  transition: all 150ms ease;
}
.br-header[data-sticky] .header-logo img {
  margin-right: var(--spacing-scale-base);
}

/*# sourceMappingURL=header.css.map*/
